import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { generateUUID } from "../../common/generators/genericGenerator";
import {
    IClusterInfo,
    IConstellationDefinition,
    IConstellationInfo,
    ClusterDeviceInfo,
    CreateClusterBackup,
} from "../../model/CarbonExternalModels";

export const initIConstellationDefinition = (): IConstellationDefinition => {
    return {
        id: { value: "NOT_SET" },
        name: "",
        description: "",
    };
};

export const initCreateClusterBackup = (): CreateClusterBackup => {
    return {
        id: { value: "NOT_SET" },
        name: "",
        supports: {
            supportsAccount: true,
            supportsApp: true,
            supportsMedia: false,
        }
    };
};

export const initConstellation = (): IConstellationInfo => {
    const clusterID: StringID = { value: generateUUID() };

    const device: ClusterDeviceInfo = {
        id: { value: "1" },
        description: "",
        name: "Video Engine 1",
        endpoint: { offline: 23, online: 20 },
        gpuInfo: [],
        deviceUsage: {
            cpu: {
                size: 1000,
                temperature: 50,
                unit: "GB",
                used: 500,
            },

            gpu: [
                { busID: "id", deviceName: "RTX 3060ti", free: 200, gpuIndex: 1, total: 500, unit: "GB", used: 300, utilization: 50 },
                { busID: "id", deviceName: "RTX 4090ti", free: 200, gpuIndex: 1, total: 500, unit: "GB", used: 300, utilization: 50 },
            ],
            memory: { available: 1000, free: 400, total: 600, unit: "GB" },
            storage: [{ available: 500, total: 2000, unit: "GB", used: 500 }],
        },
        type: "VIDEO_ENGINE",
        active: true,
        bootupTime: 1694852400,
        captureCardInfo: { inputs: ["hello"], ports: ["new"] },
        hardwareInfo: {
            biosInfo: {
                version: "version",
                releaseDate: "releaseDate",
                romSize: "romSize",
                vendor: "vendor"
            }, cacheInfoList: { cacheList: [] }, chassisInfo: {
                manufacturer: "manufacturer",
                assetTag: "assetTag",
                serialNumber: "striserialNumberng"
            },
            memoryInfoList: {
                memoryList: [],
                numberOfDevices: 1,
                maxCapacity: "string",
            }, processorInfo: {
                vendor: "vendor",
                coreCount: 1,
                coreEnabled: 1,
                version: "version",
                threadCount: 1,
                currentSpeed: 1,
            }, systemInfo: {
                manufacturer: "manufacturer",
                productName: "productName",
                serialNumber: "serialNumber",
                family: "family",
                systemID: "systemID",
                skuNumber: "skuNumber"
            }
        }
    };

    const device2: ClusterDeviceInfo = {
        id: { value: "2" },
        description: "",
        name: "Video Engine 2",
        endpoint: { offline: 23, online: 20 },
        gpuInfo: [],
        deviceUsage: {
            cpu: {
                size: 1000,
                temperature: 50,
                unit: "GB",
                used: 500,
            },

            gpu: [
                { busID: "id", deviceName: "RTX 3060ti", free: 200, gpuIndex: 1, total: 500, unit: "GB", used: 300, utilization: 50  },
                { busID: "id", deviceName: "RTX 4090ti", free: 200, gpuIndex: 1, total: 500, unit: "GB", used: 300, utilization: 50  },
            ],
            memory: { available: 1000, free: 400, total: 600, unit: "GB" },
            storage: [{ available: 500, total: 2000, unit: "GB", used: 500 }],
        },
        type: "VIDEO_ENGINE",
        active: true,
        bootupTime: 1694252400,
        captureCardInfo: { inputs: ["hello"], ports: ["new"] },
        hardwareInfo: {
            biosInfo: {
                version: "version",
                releaseDate: "releaseDate",
                romSize: "romSize",
                vendor: "vendor"
            }, cacheInfoList: { cacheList: [] }, chassisInfo: {
                manufacturer: "manufacturer",
                assetTag: "assetTag",
                serialNumber: "striserialNumberng"
            },
            memoryInfoList: {
                memoryList: [],
                numberOfDevices: 1,
                maxCapacity: "string",
            }, processorInfo: {
                vendor: "vendor",
                coreCount: 1,
                coreEnabled: 1,
                version: "version",
                threadCount: 1,
                currentSpeed: 1,
            }, systemInfo: {
                manufacturer: "manufacturer",
                productName: "productName",
                serialNumber: "serialNumber",
                family: "family",
                systemID: "systemID",
                skuNumber: "skuNumber"
            }
        }
    };

    const cluster: IClusterInfo = {
        id: clusterID,
        type: "AETHER_MANAGER",
        clusterDeviceInfo: [device, device2],
        ip: "fd12:3456:789a:1bcd:ef12:3456:789a:1bcd",
        description: "",
        name: "Aether 1",
        license: {
            active: true,
            startDate: 1643615861,
            endDate: 1743615861,
            numberOfDisplay: 100,
            displayPerInstance: 50,
            physicalGPU: 4,
            physicalCPU: 2,
            failOver: true,
        },
        mappingGroups: 100,
        activeSources: 21,
        activeDestinations: 22,
        version: "12.9",
        accessConfig: {
            accessUrl: "testnet4",
            vpnAccess: null,
        }
    };

    const cluster2: IClusterInfo = {
        id: { value: "123" },
        type: "AETHER_MANAGER",
        clusterDeviceInfo: [],
        ip: "fd12:3456:789a:1bcd:ef12:3456:789a:1bcd",
        description: "",
        name: "Aether 2",
        license: {
            active: true,
            startDate: 1643615861,
            endDate: 1743615861,
            numberOfDisplay: 100,
            displayPerInstance: 50,
            physicalGPU: 4,
            physicalCPU: 2,
            failOver: true,
        },
        mappingGroups: 100,
        activeSources: 56,
        activeDestinations: 11,
        version: "12.9",
        accessConfig: {
            accessUrl: "testnet4",
            vpnAccess: null,
        }
    };

    return {
        definition: {
            id: { value: generateUUID() },
            name: "Constellation A1",
            description:
                "In the vast expanse of digital possibilities, ideas bloom, connections spark, and words dance, weaving a tapestry of endless potential.",
        },
        cluster: [cluster, cluster2],
    };
};
